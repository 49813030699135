import { Link } from "gatsby"
import React from "react"
import Nav from "./Nav"
import Logo from "./Logo"
import Container from "./Container"

const Header = () => (
  <header className="mt-10 lg:mt-12 xl:mt-16">
    <Container>
      <h1>
        <Link to="/" className="text-black" title="Pangora">
          <Logo className="h-10" />
        </Link>
      </h1>
      {/*<Nav />*/}
    </Container>
  </header>
)

export default Header
