import React, { FC } from "react"
import cn from "classnames"

interface Props {
  narrow?: boolean
  children: React.ReactNode
}

const Container: FC<Props> = ({ narrow = false, children }) => {
  const className = cn("mx-auto px-6 md:px-16 xl:px-20", {
    "max-w-4xl": narrow,
    "max-w-8xl": !narrow,
  })

  return <div className={className}>{children}</div>
}

export default Container
