/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import "./layout.css"
import Footer from "./Footer"
import { StaticKitProvider } from "@statickit/react"

interface Props {
  children: React.ReactNode
}

const Layout: FC<Props> = ({ children }) => {
  const data: any = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <StaticKitProvider site="e291ebac09e2">
      <div>
        <a href="#hovedinnhold" className="sr-only">
          Hopp til hovedinnhold
        </a>
        <Header />
        <main id="hovedinnhold">{children}</main>
        {/*<Footer />*/}
      </div>
    </StaticKitProvider>
  )
}

export default Layout
